import React, { useCallback, useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import parse from 'html-react-parser'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { motion } from 'framer-motion'
import { useBreakpoint } from '@hooks/useBreakpoint'
import Modal from '@elements/Modal'
import { useInView } from 'react-intersection-observer'
import orderBy from 'lodash/orderBy'
import moment from 'moment/min/moment.min.js'

const Section = styled.section`
	${tw`relative pt-40 pb-20 tracking-1 mt-spacingXl-xs md:mt-spacingXl-lg xl:mt-spacingXl-master`}
	.slick-track {
		${tw`ml-0`}
	}
`
const Headline = tw.h2`bottom-full translate-y-spacingXs-master lg:translate-y-spacingS-master left-offset-xs md:left-16 absolute xs:w-3/4 2xl:max-w-[900px] pr-5 md:pr-20`
const Card = tw.div`pr-3 md:pr-6`
const CardWrap = tw.div``
const ImageWrapper = tw(motion.div)``
const VideoWrapper = tw(motion.div)``
const CardImage = tw.div`relative flex justify-center items-end`
const CardName = tw.p`px-2 pt-5 text-base md:text-copyXl`
const CardDescription = tw.p`px-2 text-copyXl pt-1 font-futuraBold`
const CardHoverInfo = tw(motion.p)`pointer-events-none font-futuraBook text-sm lg:text-copyXl text-white text-center absolute flex justify-center bottom-5 lg:bottom-8 left-5 right-5 mx-auto`

const Arrows = tw.div`absolute top-20 md:top-12 right-5 md:right-16 md:mr-2 md:-translate-x-1/2 grid grid-cols-2 gap-10`
const PrevArrow = tw.button`w-12 md:w-16`
const NextArrow = tw.button`w-12 md:w-16`

const WpAcfTeasercarouselBlock = ({ data, locale }) => {
	const [activeSlide, setActiveSlide] = React.useState(0)
	const { isScreenLg, isScreenMd, isScreenSm, isScreenXs } = useBreakpoint()
	const [races, setRaces] = React.useState(false)
	const { anchor } = data.attributes
	const module = data.modulesTeaserCarousel
	const lang = locale.substr(0, 2)
	const dynamicRaces = useStaticQuery(graphql`
		{
			wp {
				acfOptionsRaceCalendar {
					optionsRaceCalendar {
						...DetailsPostRacesFragment
					}
				}
			}
		}
	`)
	const teasers = module.dynamicContent ? dynamicRaces.wp.acfOptionsRaceCalendar.optionsRaceCalendar.races : module.teasers
	const sortRaces = () => {
		const sortedData = orderBy(teasers, [(item) => item.raceDate], ['asc'])
		setRaces(sortedData)
		if (sortedData) {
			const __filteredDates = sortedData.filter((item) => {
				const today = moment().format('YYYY-MM-DD HH:mm:ss')
				const raceDate = moment(item.raceDate)
				const raceWeekend = raceDate.add(2, 'd')
				return moment(today) < raceWeekend
			})
			const slideIndex = sortedData.findIndex((item, index) => {
				if (__filteredDates[0] === item) return index
			})
			setActiveSlide(slideIndex)
		}
	}

	useEffect(() => {
		if (module.dynamicContent && !races) {
			sortRaces()
		} else {
			setRaces(teasers)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const slider = React.useRef()

	const sliderSettings = {
		dots: false,
		arrows: true,
		margin: 20,
		infinite: false,
		speed: 500,
		swipeToSlide: true,
		touchThreshold: 10,
		slidesToShow: 3.5,
		slidesToScroll: 1,
		afterChange: (current) => setActiveSlide(Math.ceil(current) + 3),
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 4.5,
					afterChange: (current) => setActiveSlide(Math.ceil(current) + 4),
				},
			},
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 3.5,
					afterChange: (current) => setActiveSlide(Math.ceil(current) + 3),
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2.5,
					afterChange: (current) => setActiveSlide(Math.ceil(current) + 2),
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1.5,
					afterChange: (current) => setActiveSlide(Math.ceil(current) + 1),
				},
			},
		],
	}
	//disable onClick when swiping
	const [swiped, setSwiped] = useState(false)
	const handleSwiped = useCallback(() => {
		setSwiped(true)
	}, [setSwiped])

	const handleOnItemClick = useCallback(
		(e) => {
			if (swiped) {
				e.stopPropagation()
				e.preventDefault()
				setSwiped(false)
			}
		},
		[swiped]
	)
	const [currentYTPlaying, setCurrentYTPlaying] = React.useState('')
	const [modalOpened, setModalOpened] = React.useState(false)
	const ytHandle = (item) => {
		setModalOpened(true)
		setCurrentYTPlaying(item)
	}

	const getVideoId = (url) => {
		url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
		return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0] // eslint-disable-line
	}
	const [ref, isVisible] = useInView({ threshold: 1, triggerOnce: true })
	return (
		teasers?.length > 0 && (
			<Section {...(anchor !== '' ? { id: anchor } : {})} className='emf-mb-spacing' ref={ref}>
				<div tw='absolute top-0 bottom-0 left-0 right-20 xl:right-60 bg-superLight z-0'></div>
				{module.headline && <Headline className='headline-h2'>{parse(module.headline)}</Headline>}
				<div tw='relative z-10 pl-offset-xs pr-0 md:pl-spacingXl-lg'>
					{isVisible && (
						<Slider ref={slider} onSwipe={handleSwiped} initialSlide={activeSlide} {...sliderSettings}>
							{teasers.map((item, index) => {
								if (item.youtubeEmbed && item.youtube) {
									return <YoutubeCard key={`yt-card-${index}`} onClickCapture={handleOnItemClick} item={item} index={index} onClick={() => ytHandle(item.youtube)} />
								}
								return <CarouselCard key={`carousel-card-${index}`} onClickCapture={handleOnItemClick} item={item} index={index} locale={locale} />
							})}
						</Slider>
					)}
				</div>
				{(teasers.length > 4 || (teasers.length > 2 && (isScreenLg || isScreenMd || isScreenSm)) || (teasers.length > 1 && isScreenXs)) && (
					<Arrows>
						<PrevArrow aria-label='previous-slide' onClick={() => activeSlide !== 0 && slider?.current.slickPrev()}>
							<svg className='w-full h-full' width='63' height='58' viewBox='0 0 63 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path d='M-1.27944e-06 29L29.0318 -2.96959e-06L31.8085 2.77364L7.52791 27.0276L63 27.0276L63 30.9724L7.52791 30.9724L31.8085 55.2264L29.0318 58L-1.27944e-06 29Z' fill='black' />
							</svg>
						</PrevArrow>
						<NextArrow aria-label='next-slide' onClick={() => activeSlide < teasers.length && slider?.current.slickNext()}>
							<svg className='w-full h-full' width='63' height='58' viewBox='0 0 63 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path d='M63 29L33.9682 58L31.1915 55.2264L55.4721 30.9724L0 30.9724L0 27.0276L55.4721 27.0276L31.1915 2.77365L33.9682 0L63 29Z' fill='black' />
							</svg>
						</NextArrow>
					</Arrows>
				)}
				<Modal state={modalOpened} onClick={() => setModalOpened(false)}>
					{modalOpened && currentYTPlaying && (
						<ModalIframeWrap>
							<iframe
								title={`EMF Racing ${getVideoId(currentYTPlaying)}`}
								src={`https://www.youtube-nocookie.com/embed/${getVideoId(currentYTPlaying)}?rel=0&autoplay=1`}
								frameBorder='0'
								className='!w-full !h-full '
								allow='autoplay; encrypted-media'
								allowFullScreen
							></iframe>
						</ModalIframeWrap>
					)}
				</Modal>
			</Section>
		)
	)
}

const CarouselCard = ({ item, index, onClickCapture, locale }) => {
	const [hovering, setHovering] = React.useState(false)
	const videoRef = React.useRef()
	const itemData = item

	const { isMobile } = useBreakpoint()
	const getTeaserUri = () => {
		if (item.trackPostLocation?.locale?.locale === locale) {
			return item.trackPostLocation?.uri
		}
		if (item.trackPostLocation?.translated?.length > 0 && item.trackPostLocation?.translated[0]?.locale?.locale === locale) {
			return item.trackPostLocation?.translated[0].uri
		}
		return false
	}
	const regionNames = new Intl.DisplayNames([locale.substr(0, 2)], { type: 'region' })

	const itemUri = item.trackPostLocation !== undefined ? getTeaserUri() : false

	const handleHovering = (state, index) => {
		setHovering(state)
		if (!itemData.imageOnHover?.localFile && !itemData.videoOnHover) return
		if (itemData.videoOnHover && !itemData.imageOnHover?.localFile && state) {
			if (itemData.videoOnHover?.localFile?.extension !== 'mp4') return
			videoRef.current.setAttribute('autoPlay', true)
			videoRef.current.play()
		}
		if (itemData.videoOnHover && !itemData.imageOnHover?.localFile && !state) {
			if (itemData.videoOnHover?.localFile?.extension !== 'mp4') return
			videoRef.current.pause()
			videoRef.current.currentTime = 0
			videoRef.current.setAttribute('autoPlay', false)
		}
	}
	const itemNavigate = (uri) => {
		if (!uri) return
		navigate(uri)
	}

	const [ref, isVisible] = useInView({ threshold: 1 })

	React.useEffect(() => {
		if (isVisible && isMobile) {
			handleHovering(true)
		}
		if (!isVisible && isMobile) {
			handleHovering(false)
		}
	}, [isVisible, isMobile]) // eslint-disable-line react-hooks/exhaustive-deps

	const parseCardName = (name) => {
		if (name.includes('|') && item.trackPostLocation?.postTypeRaces?.countryCode) {
			const parseCardName = itemData.teaserName?.split('|')
			if (isMobile) {
				return parse(regionNames.of(item.trackPostLocation?.postTypeRaces?.countryCode?.toUpperCase()) + ' |<br />' + parseCardName[1])
			} else {
				return parse(regionNames.of(item.trackPostLocation?.postTypeRaces?.countryCode?.toUpperCase()) + ' | ' + parseCardName[1])
			}
		}
		if (name.includes('|') && !item.trackPostLocation?.postTypeRaces?.countryCode) {
			const parseCardName = itemData.teaserName?.split('|')
			if (isMobile) {
				return parse(parseCardName[0] + '|<br />' + parseCardName[1])
			} else {
				return parse(parseCardName[0] + '| ' + parseCardName[1])
			}
		}
		return name
	}

	return (
		<CardWrap onClick={() => itemNavigate(itemUri)} onClickCapture={onClickCapture} className={`${itemUri && 'cursor-pointer'}`}>
			<Card ref={ref}>
				<CardImage onMouseOver={() => !hovering && handleHovering(true)} onMouseOut={() => hovering && handleHovering(false)}>
					<div className='absolute inset-0 w-full h-full aspect-w-1 aspect-h-1'>
						<GatsbyImage tw='w-full h-full' image={getImage(itemData.image?.localFile)} alt={itemData.image?.altText} />
					</div>
					{(itemData.imageOnHover?.localFile || (itemData.imageOnHover?.localFile && itemData.videoOnHover)) && (
						<ImageWrapper className='absolute inset-0' variants={imageVariants} initial='hidden' animate={hovering ? 'shown' : 'hidden'}>
							<GatsbyImage tw='w-full h-full' image={getImage(itemData.imageOnHover?.localFile)} alt={itemData.imageOnHover?.altText} />
						</ImageWrapper>
					)}
					{itemData.videoOnHover && !itemData.imageOnHover?.localFile && (
						<VideoWrapper className='absolute inset-0 pointer-events-none' variants={imageVariants} initial='hidden' animate={hovering ? 'shown' : 'hidden'}>
							<video ref={videoRef} controls={false} autoPlay={false} muted playsInline src={itemData.videoOnHover?.localFile?.publicURL} className='object-cover w-full h-full'></video>
						</VideoWrapper>
					)}
					{itemData.shortInfo && (
						<CardHoverInfo variants={infoVariants} initial='hidden' animate={hovering ? 'shown' : 'hidden'}>
							{itemData.shortInfo}
						</CardHoverInfo>
					)}
				</CardImage>
				{itemData.teaserName && <CardName>{parseCardName(itemData.teaserName)}</CardName>}
				{itemData.teaserDescription && <CardDescription>{itemData.teaserDescription}</CardDescription>}
			</Card>
		</CardWrap>
	)
}

const PlayIcon = ({ onClick, className }) => (
	<div onClick={onClick} className={className}>
		<svg viewBox='0 0 83 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M80.0055 44.8489C83.152 46.7681 83.2115 51.316 80.1162 53.3168L9.60764 98.8919C6.30542 101.026 1.94524 98.6898 1.89383 94.7581L0.726338 5.46693C0.674931 1.53526 4.97253 -0.914535 8.32943 1.13288L80.0055 44.8489Z'
				fill='white'
			/>
		</svg>
	</div>
)
const StyledPlayIcon = tw(PlayIcon)`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer z-10 w-[21%]`
const ModalIframeWrap = tw.div`aspect-w-11 aspect-h-16 md:aspect-w-16 md:aspect-h-9 2xl:aspect-none w-full max-w-[800px] min-h-[33%] 2xl:h-[500px] 2xl:w-full max-h-[450px]`

const YoutubeCard = ({ item, index, onClickCapture, onClick }) => {
	return (
		<>
			<CardWrap onClick={onClick} onClickCapture={onClickCapture}>
				<Card>
					<CardImage>
						<StyledPlayIcon />
						<div className='absolute inset-0 w-full h-full aspect-w-1 aspect-h-1'>
							<GatsbyImage tw='w-full h-full' image={getImage(item.image?.localFile)} alt={item.image?.altText} />
						</div>
					</CardImage>
					{item.teaserName && <CardName>{item.teaserName}</CardName>}
					{item.teaserDescription && <CardDescription>{item.teaserDescription}</CardDescription>}
				</Card>
			</CardWrap>
		</>
	)
}

const imageVariants = {
	shown: {
		opacity: 1,
		transition: {
			duration: 0.3,
		},
	},
	hidden: {
		opacity: 0,
		transition: {
			duration: 1,
		},
	},
}
const infoVariants = {
	shown: {
		y: 0,
		opacity: 1,
	},
	hidden: {
		y: 50,
		opacity: 0,
	},
}

export default WpAcfTeasercarouselBlock
